import React, { useState } from "react";
import { Grid, Paper, Button, TextField } from "@mui/material";
import { useEffect } from "react";
import BASE_URL from "../../helpers/baseURL";
import TablePagination from "@mui/material/TablePagination";
import formatDate from "../../helpers/formatDate";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function AddDataPart1() {
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [changes, setChanges] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedEngineerNumber, setSelectedEngineerNumber] = useState("");
  const [selectedEngineerName, setSelectedEngineerName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedRowData, setSelectedRowData] = useState(null);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Function to handle the row selection
  const handleRowSelection = (index) => {
    const adjustedIndex = index + page * rowsPerPage; // Calculate the actual index in the entire dataset
    setSelectedRowIndex(adjustedIndex);

    // Store the selected row's data
    const selectedRowData = filteredData[adjustedIndex];
    setSelectedRowData(selectedRowData);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter the data based on the search query
  const filteredData = fetchedData.filter((item) => {
    // Customize the filtering logic for case-insensitive search
    // return item.serial_number.toLowerCase() === searchQuery.toLowerCase();
    return item.serial_number.includes(searchQuery);
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      //fetch data from requests table
      const response = await fetch(`${BASE_URL}/requests`);
      const fetchedData = await response.json();
      setFetchedData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Generic function to handle changes in table cells
  const handleCellChange = async (columnName, event) => {
    const newData = [...data];
    newData[columnName] = event.target.value;
    setData(newData);

    // Update the changes state to track the modified columns
    setChanges((prevChanges) => ({
      ...prevChanges,
      [columnName]: event.target.value,
    }));
    if (columnName === "employee_number") {
      const selectedNumber = event.target.value;
      setSelectedEngineerNumber(selectedNumber);

      try {
        const response = await fetch(`${BASE_URL}/engineer/${selectedNumber}`);
        const data = await response.json();
        console.log("data", data[0].employee_name);
        setSelectedEngineerName(data[0].employee_name);
        console.log("selectedEngineerName", selectedEngineerName);
      } catch (error) {
        console.error("Error fetching engineer name:", error);
      }
    }
  };

  const handleAddClick = async () => {
    if (selectedRowIndex >= 0 && selectedRowIndex < fetchedData.length) {
      try {
        const adjustedIndex = startIndex + selectedRowIndex;
        const selectedRowData = filteredData[adjustedIndex];

        const updatedData = [...fetchedData];
        updatedData[adjustedIndex] = { ...selectedRowData, ...changes };

        const response = await fetch(`${BASE_URL}/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData[adjustedIndex]),
        });

        const responseData = await response.json();
        if (response.ok) {
          updatedData.splice(adjustedIndex, 1);
          setFetchedData(updatedData);
          setChanges({});
          setSelectedRowIndex(-1);
          alert(responseData.message);
        } else {
          alert("Error adding information.");
        }
      } catch (error) {
        console.error("Error adding information:", error);
      }
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 3,
          height: 500,
          width: "97%",
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {fetchedData.length === 0 ? (
          <p>No data available for showing.</p>
        ) : (
          <React.Fragment>
            <Grid
              container
              justifyContent="flex-end" // Align items to the right
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              <Grid item>
                <TextField
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  label="Search by Serial Number"
                  variant="outlined"
                  size="small"
                  sx={{ width: "220px" }}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#fff",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ minWidth: "60px" }}>
                      Customer Code
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Business Partner
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Hospital Name
                    </TableCell>
                    <TableCell sx={{ minWidth: "70px" }}>
                      Customer Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>Province</TableCell>
                    <TableCell sx={{ minWidth: "70px" }}>City</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Equipment Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "60px" }}>
                      Serial Number
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Install Date
                    </TableCell>
                    <TableCell sx={{ minWidth: "50px" }}>
                      Warranty Years
                    </TableCell>

                    <TableCell sx={{ minWidth: "80px" }}>
                      Machine Status
                    </TableCell>
                    <TableCell sx={{ minWidth: "60px" }}>
                      Contract Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "60px" }}>Owner</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Arrival Date
                    </TableCell>
                    <TableCell sx={{ minWidth: "60px" }}>
                      Engineer Number
                    </TableCell>
                    <TableCell sx={{ minWidth: "50px" }}>
                      Engineer Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <TableRow
                        key={item.serial_number}
                        onClick={() => handleRowSelection(index)}
                        sx={{
                          backgroundColor:
                            selectedRowIndex === index + page * rowsPerPage
                              ? "#f0f0f0"
                              : "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell>
                          <textarea
                            type="number"
                            onChange={(event) =>
                              handleCellChange("customer_code", event)
                            }
                            style={{ width: "60px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("business_partner", event)
                            }
                            style={{ width: "80px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("hospital_name", event)
                            }
                            style={{ width: "80px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("customer_type", event)
                            }
                            style={{ width: "70px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("province", event)
                            }
                            style={{ width: "80px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("city", event)
                            }
                            style={{ width: "70px" }}
                          />
                        </TableCell>
                        <TableCell>{item.equipment_type}</TableCell>
                        <TableCell>{item.serial_number}</TableCell>
                        <TableCell>
                          {" "}
                          <input
                            type="date"
                            onChange={(event) =>
                              handleCellChange("install_day", event)
                            }
                            style={{ width: "110px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="number"
                            onChange={(event) =>
                              handleCellChange(
                                "number_of_warranty_years",
                                event
                              )
                            }
                            style={{ width: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("machine_status", event)
                            }
                            style={{ width: "80px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("contract_type", event)
                            }
                            style={{ width: "60px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("owner", event)
                            }
                            style={{ width: "60px" }}
                          />
                        </TableCell>
                        <TableCell>{formatDate(item.arrival_date)}</TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            onChange={(event) =>
                              handleCellChange("employee_number", event)
                            }
                            style={{ width: "60px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {selectedRowIndex === index + page * rowsPerPage
                            ? selectedEngineerName
                            : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[1, 5, 10]}
                component="div"
                count={fetchedData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </TableContainer>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleAddClick}
              sx={{ margin: "10px auto" }}
            >
              Post
            </Button>
          </React.Fragment>
        )}
      </Paper>
    </Grid>
  );
}

export default AddDataPart1;
