import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BASE_URL from "../../helpers/baseURL";
import TablePagination from "@mui/material/TablePagination";
import formatDate from "../../helpers/formatDate";

const DeleteTable = () => {
  const { hospitalCode } = useParams();
  const hospitalCodeInteger = parseInt(hospitalCode, 10);

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((item) => {
    // Customize the filtering logic for case-insensitive search
    // return item.serial_number.toLowerCase() === searchQuery.toLowerCase();
    return item.serial_number.includes(searchQuery);
  });

  const handleDelete = async () => {
    if (selectedRowIndex >= 0 && selectedRowIndex < filteredData.length) {
      try {
        const adjustedIndex = startIndex + selectedRowIndex; // Adjust the index within the displayed rows
        const selectedRow = filteredData[selectedRowIndex];
        console.log("selectedRow", selectedRow);

        const response = await fetch(
          `${BASE_URL}/delete/${selectedRow.serial_number}`,
          {
            method: "DELETE",
          }
        );

        const responseData = await response.json();
        if (response.ok) {
          const updatedData = data.filter(
            (item) => item.serial_number !== selectedRow.serial_number
          );
          setData(updatedData);
          console.log("responseData", responseData);
          setSelectedRowIndex(-1); // Deselect the row
          alert(responseData.message);
        } else {
          alert("Error deleting row.");
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/hospitalCode/${hospitalCode}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 3,
          height: 500,
          width: "97%",
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.length === 0 ? (
          <p>No data available for showing.</p>
        ) : (
          <React.Fragment>
            <Grid
              container
              justifyContent="flex-end" // Align items to the right
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              <Grid item>
                <TextField
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  label="Search by Serial Number"
                  variant="outlined"
                  size="small"
                  sx={{ width: "220px" }}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Customer Code
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Business Partner
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Hospital Name
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Customer Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>Province</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>City</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Equipment Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Serial Number
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Install Date
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      End of Warranty
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Warranty Years
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Machine Status
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Contract Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>Owner</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Engineer Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <TableRow
                        key={item.serial_number}
                        onClick={() => setSelectedRowIndex(index)}
                        sx={{
                          backgroundColor:
                            selectedRowIndex === index
                              ? "#f0f0f0"
                              : "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell>{item.customer_code}</TableCell>
                        <TableCell>{item.business_partner}</TableCell>
                        <TableCell>{item.hospital_name}</TableCell>
                        <TableCell>{item.customer_type}</TableCell>
                        <TableCell>{item.province}</TableCell>
                        <TableCell>{item.city}</TableCell>
                        <TableCell>{item.equipment_type}</TableCell>
                        <TableCell>{item.serial_number}</TableCell>
                        <TableCell>
                          {formatDate(item.install_day, false)}
                        </TableCell>
                        <TableCell>{item.number_of_warranty_years}</TableCell>
                        <TableCell>
                          {formatDate(item.end_of_warranty, true)}
                        </TableCell>
                        <TableCell>{item.machine_status}</TableCell>
                        <TableCell>{item.contract_type}</TableCell>
                        <TableCell>{item.owner}</TableCell>
                        <TableCell>{item.employee_name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </TableContainer>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleDelete}
              sx={{ margin: "10px auto" }}
            >
              Delete
            </Button>
          </React.Fragment>
        )}
      </Paper>
    </Grid>
  );
};

export default DeleteTable;
