import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Grid,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  InputAdornment,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import BASE_URL from "../helpers/baseURL";

const filterOptions = [
  "Hospital Name",
  "Hospital Code",
  "Instrument Type",
  "Customer Type",
  "Province",
  "City",
  "Machine Status",
  "Contract Type",
  "Owner of Instrument",
  "Install Dates",
  "Installed Base",
  "Current Year Installed Devices",
  "On Transit",
];

function SearchPage() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("Choose search filter");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(""); // Add state for start date
  const [endDate, setEndDate] = useState(""); // Add state for end date
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [showTextField, setShowTextField] = useState(true); // Add this state variable
  const [search, setSearch] = useState(false);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = async (option) => {
    setAnchorEl(null);
    setSelectedFilter(option);
    setShowDatePickers(option === "Install Dates");
    setShowTextField(
      option !== "Installed Base" &&
        option !== "Current Year Installed Devices" &&
        option !== "Province" &&
        option !== "City" &&
        option !== "Customer Type" &&
        option !== "On Transit"
    );
    setSearch(
      option === "Installed Base" ||
        option === "Current Year Installed Devices" ||
        option === "On Transit"
    );

    if (
      option === "Province" ||
      option === "City" ||
      option === "Customer Type" ||
      option === "Owner of Instrument" ||
      option === "Instrument Type"
    ) {
      try {
        console.log("option: ", option);
        const response = await fetch(`${BASE_URL}/dropDown/${option}`); // Replace with your API call
        const data = await response.json();
        console.log("data: ", data);
        const formattedOptions = data.map((item) => ({
          value: item[Object.keys(item)[0]], // Get the first property's value
          label: item[Object.keys(item)[0]],
        }));
        console.log("formattedOptions: ", formattedOptions);
        setDropdownOptions(formattedOptions); // Assuming data is an array of options
        setSelectedDropdownOption(""); // Clear the selected option
        console.log("dropdownOptions: ", dropdownOptions);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    } else {
      setDropdownOptions([]);
      setSelectedDropdownOption("");
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (
      selectedFilter === "Installed Base" ||
      selectedFilter === "Current Year Installed Devices" ||
      selectedFilter === "On Transit"
    ) {
      handleSearch();
    }
  }, [search, setSearch]);

  const handleSearch = () => {
    //chech that the user has selected a filter
    if (selectedFilter === "Choose search filter") {
      alert("Please select a filter");
      return;
    }
    if (selectedDropdownOption) {
      navigate(`/search-table/${selectedFilter}/${selectedDropdownOption}`);
    }
    if (selectedFilter && searchQuery) {
      // Search with filter and search query
      navigate(`/search-table/${selectedFilter}/${searchQuery}`);
    } else if (selectedFilter === "Install Dates" && startDate && endDate) {
      // Search with filter and date range
      navigate(`/search-table/${selectedFilter}/${startDate}/${endDate}`);
    } else if (
      selectedFilter === "Installed Base" ||
      selectedFilter === "Current Year Installed Devices" ||
      selectedFilter === "On Transit"
    ) {
      console.log("selectedFilter: ", selectedFilter);
      // Search with filter and date range
      navigate(`/search-table/${selectedFilter}`);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 300,
          width: 370,
        }}
      >
        <Grid container direction="column" alignItems="center">
          <h1>Filter and Search</h1>
          <Grid container alignItems="center">
            {showDatePickers ? (
              <>
                <TextField
                  autoComplete="off"
                  hiddenLabel
                  id="start-date"
                  type="date"
                  variant="outlined"
                  required
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="body2" style={{ fontSize: "9px" }}>
                          From:
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "170px", // Adjust the width as needed
                    margin: "50px auto",
                    borderRadius: "10%",
                  }}
                />

                <TextField
                  autoComplete="off"
                  hiddenLabel
                  id="end-date"
                  type="date"
                  variant="outlined"
                  required
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="body2" style={{ fontSize: "9px" }}>
                          To:
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "160px", // Adjust the width as needed
                    margin: "50px auto",
                    borderRadius: "10%",
                  }}
                />
              </>
            ) : (
              <>
                {dropdownOptions.length > 0 ? (
                  <TextField
                    autoComplete="off"
                    id="outlined-select-currency"
                    select
                    label="Select"
                    value={selectedDropdownOption}
                    onChange={(e) => setSelectedDropdownOption(e.target.value)}
                    sx={{
                      margin: "50px auto",
                      borderRadius: "10%",
                      width: "300px",
                    }}
                  >
                    {dropdownOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    autoComplete="off"
                    id="outlined-basic"
                    label="Search"
                    placeholder={selectedFilter}
                    variant="outlined"
                    required
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{
                      margin: "50px auto",
                      borderRadius: "10%",
                      width: "300px",
                    }}
                  />
                )}
              </>
            )}
            <FilterAltOutlinedIcon
              fontSize="medium"
              aria-controls="filter-menu"
              aria-haspopup="true"
              onClick={handleFilterClick}
              sx={{
                cursor: "pointer",
                color: "#dc3545",
                border: "2px solid #dc3545",
                borderRadius: "10%",
                padding: "14px 3px",
                marginLeft: "5px",
                "&:hover": {
                  color: "#f18c9c",
                  borderColor: "#f18c9c",
                },
              }}
            />
          </Grid>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSearch} // Call the handleSearch function on button click
            sx={{ margin: "10px auto" }}
          >
            Search
          </Button>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleFilterClose(selectedFilter)}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option} onClick={() => handleFilterClose(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default SearchPage;
