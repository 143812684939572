import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  Paper,
  Grid,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BASE_URL from "../helpers/baseURL";
import { useNavigate } from "react-router-dom";

//regex for password validation that matches the following criteria:
//at least 8 characters long
//at least 1 uppercase letter
//at least 1 lowercase letter
//at least 1 number
//at least 1 special character
const passowrdRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8})"
);
//regex for email validation
const emailRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@sa.stago.com$");

function SignUp() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeIdError, setEmployeeIdError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = () => {
    setEmailError("");
  };

  const handlePasswordChange = () => {
    setPasswordError("");
  };

  const handleConfirmPasswordChange = () => {
    setConfirmPasswordError("");
  };
  const handleEmployeeIdChange = () => {
    setEmployeeIdError("");
  };
  const handleNameChange = () => {
    setNameError("");
  };

  const validate = () => {
    const email = document.getElementById("outlined-basic").value;
    const password = document.getElementById("outlined-password").value;
    const confirmPassword = document.getElementById(
      "outlined-confirm-password"
    ).value;
    let isValid = true;

    // if (!emailRegex.test(email)) {
    //   setEmailError("Invalid email");
    //   isValid = false;
    // }

    if (!passowrdRegex.test(password)) {
      setPasswordError("Invalid password");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    return isValid;
  };

  const handleSignup = async () => {
    if (!validate()) return;

    const email = document.getElementById("outlined-basic").value;
    const password = document.getElementById("outlined-password").value;
    const employee_name = document.getElementById("outlined-basic-name").value;
    const employee_number = document.getElementById(
      "outlined-basic-number"
    ).value;

    //call API to register
    try {
      const response = await fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          employee_name: employee_name,
          employee_number: employee_number,
          authority: "engineer",
        }),
      });
      const responseData = await response.json();
      if (responseData.message === "User already exists") {
        setLoginError("User already exists");
        alert("User already exists");
      } else {
        alert("User created successfully");
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 600,
          width: 280,
        }}
      >
        <Grid align="center">
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <h1>Sign Up</h1>
          {loginError && (
            <Typography
              color="error"
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
            >
              {loginError}
            </Typography>
          )}
          <TextField
            id="outlined-basic-name"
            label="Full Name"
            placeholder="Enter Full Name"
            variant="outlined"
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleNameChange}
            error={Boolean(nameError)}
            helperText={nameError}
          />
          <TextField
            id="outlined-basic-number"
            label="Employee Number"
            placeholder="Enter Your Employee Number"
            variant="outlined"
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleEmployeeIdChange}
            error={Boolean(employeeIdError)}
            helperText={employeeIdError}
          />
          <TextField
            id="outlined-basic"
            label="Email"
            placeholder="Enter Email"
            variant="outlined"
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleEmailChange}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <TextField
            id="outlined-password"
            label="Password"
            placeholder="Enter Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handlePasswordChange}
            error={Boolean(passwordError)}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-confirm-password"
            label="Confim Password"
            placeholder="Re enter Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleConfirmPasswordChange}
            error={Boolean(confirmPasswordError)}
            helperText={confirmPasswordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            sx={{ margin: "10px" }}
            onClick={handleSignup}
          >
            Sign Up
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}
export default SignUp;
