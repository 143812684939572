import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import BASE_URL from "../helpers/baseURL";
import formatDate from "../helpers/formatDate";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";

const SearchTable = () => {
  const { filterChoice, searchQuery, date1, date2 } = useParams();
  var newFilter = "";
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  if (filterChoice === "Hospital Name") {
    newFilter = "hospital";
  } else if (filterChoice === "Hospital Code") {
    newFilter = "hospitalCode";
  } else if (filterChoice === "Instrument Type") {
    newFilter = "equipment";
  } else if (filterChoice === "Customer Type") {
    newFilter = "customerType";
  } else if (filterChoice === "Province") {
    newFilter = "province";
  } else if (filterChoice === "City") {
    newFilter = "city";
  } else if (filterChoice === "Machine Status") {
    newFilter = "status";
  } else if (filterChoice === "Contract Type") {
    newFilter = "contractType";
  } else if (filterChoice === "Owner of Instrument") {
    newFilter = "owner";
  } else if (filterChoice === "Install Dates") {
    newFilter = "installDate";
  } else if (filterChoice === "Installed Base") {
    newFilter = "instrumentNum";
  } else if (filterChoice === "Current Year Installed Devices") {
    newFilter = "currentYear";
  } else if (filterChoice === "On Transit") {
    newFilter = "requests";
  }
  console.log("filter", newFilter);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let apiUrl = `${BASE_URL}/${newFilter}`;
      if (searchQuery) {
        apiUrl += `/${searchQuery}`;
        console.log(apiUrl);
      } else if (date1 && date2) {
        apiUrl += `/${date1}/${date2}`;
      }

      const response = await fetch(apiUrl);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderTransitTable = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "80px" }}>Serial Number</TableCell>
              <TableCell sx={{ minWidth: "80px" }}>Equipment Type</TableCell>
              <TableCell sx={{ minWidth: "80px" }}>Arrival Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(startIndex, endIndex).map((item) => (
              <TableRow key={item.serial_number}>
                <TableCell>{item.serial_number}</TableCell>
                <TableCell>{item.equipment_type}</TableCell>
                <TableCell>{formatDate(item.arrival_date)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[8, 16, 24]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
    );
  };

  const renderInstrumentTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "80px" }}>Instrument Type</TableCell>
              <TableCell sx={{ minWidth: "80px" }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item.serial_number}
                component={Link} // Add Link component to make rows clickable
                to={
                  newFilter === "province"
                    ? `/details/${item.equipment_type}/${searchQuery}`
                    : newFilter === "instrumentNum"
                    ? `/details/${item.equipment_type}`
                    : newFilter === "currentYear"
                    ? `/details/${item.equipment_type}/${"true"}`
                    : newFilter === "customerType"
                    ? `/details/${item.equipment_type}/${searchQuery}`
                    : "/default-link"
                }
                style={{ textDecoration: "none", color: "inherit" }} // Style for Link
              >
                <TableCell>{item.equipment_type}</TableCell>
                <TableCell>{item.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderDefaultTable = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "#fff",
            }}
          >
            <TableRow>
              <TableCell sx={{ minWidth: "70px" }}>Customer Code</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Business Partner</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Hospital Name</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Customer Type</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Province</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>City</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Equipment Type</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Serial Number</TableCell>
              <TableCell sx={{ minWidth: "80px" }}>Install Date</TableCell>
              <TableCell sx={{ minWidth: "60px" }}>Warranty Years</TableCell>
              <TableCell sx={{ minWidth: "80px" }}>End of Warranty</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Machine Status</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Contract Type</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Owner</TableCell>
              <TableCell sx={{ minWidth: "70px" }}>Engineer Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: "400px", overflowY: "auto" }}>
            {data.slice(startIndex, endIndex).map((item) => (
              <TableRow key={item.serial_number}>
                <TableCell>{item.customer_code}</TableCell>
                <TableCell>{item.business_partner}</TableCell>
                <TableCell>{item.hospital_name}</TableCell>
                <TableCell>{item.customer_type}</TableCell>
                <TableCell>{item.province}</TableCell>
                <TableCell>{item.city}</TableCell>
                <TableCell>{item.equipment_type}</TableCell>
                <TableCell>{item.serial_number}</TableCell>
                <TableCell>{formatDate(item.install_day, false)}</TableCell>
                <TableCell>{item.number_of_warranty_years}</TableCell>
                <TableCell>{formatDate(item.end_of_warranty, true)}</TableCell>
                <TableCell>{item.machine_status}</TableCell>
                <TableCell>{item.contract_type}</TableCell>
                <TableCell>{item.owner}</TableCell>
                <TableCell>{item.employee_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[8, 16, 24]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 650,
          width: "100%",
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.length === 0 ? (
          <p>No data available for showing.</p>
        ) : (
          <React.Fragment>
            {filterChoice === "Installed Base" ||
            filterChoice === "Current Year Installed Devices" ||
            filterChoice === "Province" ||
            filterChoice === "Customer Type"
              ? renderInstrumentTable()
              : filterChoice === "On Transit"
              ? renderTransitTable()
              : renderDefaultTable()}

            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ margin: "10px auto" }}
            >
              <CSVLink
                data={data}
                filename={`${filterChoice}${
                  searchQuery ? `-${searchQuery}` : ""
                }${date1 ? `-${date1}` : ""}${date2 ? `-${date2}` : ""}.csv`}
                style={{ color: "white", textDecoration: "none" }}
              >
                Export Data
              </CSVLink>
            </Button>
          </React.Fragment>
        )}
      </Paper>
    </Grid>
  );
};

export default SearchTable;
