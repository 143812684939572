import "./App.css";
import WebRoutes from "./routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#dc3545",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <WebRoutes />
    </ThemeProvider>
  );
}

export default App;
