import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import BASE_URL from "../../helpers/baseURL";
import formatDate from "../../helpers/formatDate";
import { Button } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

//call api to get the options of buisness partner

const UpdateTable = () => {
  const { hospitalCode } = useParams();
  const hospitalCodeInteger = parseInt(hospitalCode, 10);

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);

  const [data, setData] = useState([]);
  const [changes, setChanges] = useState({});
  const [oldSerialNumbers, setOldSerialNumbers] = useState([]);
  const [oldBusinessPartners, setOldBusinessPartner] = useState([]);
  const [oldCities, setOldCity] = useState([]);
  const [oldHospitalNames, setOldHospitalName] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");

  const [modifiedData, setModifiedData] = useState({});

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((item) => {
    // Customize the filtering logic for case-insensitive search
    // return item.serial_number.toLowerCase() === searchQuery.toLowerCase();
    return item.serial_number.includes(searchQuery);
  });
  const updateModifiedData = (index, columnName, value) => {
    setModifiedData((prev) => {
      const updatedIndex = Object.keys(prev).find(
        (key) => parseInt(key) === index
      );

      if (updatedIndex !== undefined) {
        const modifiedRow = { ...prev[updatedIndex], [columnName]: value };
        return { ...prev, [updatedIndex]: modifiedRow };
      } else {
        const modifiedRow = { [columnName]: value };
        return { ...prev, [index]: modifiedRow };
      }
    });
  };

  const handleCellChange = (index, columnName, event) => {
    const value = event.target.value;

    // Calculate the index of the row in the complete dataset
    const dataIndex = data.findIndex((item) => {
      return (
        item.serial_number === filteredData[startIndex + index]?.serial_number
      );
    });

    if (dataIndex !== -1) {
      const updatedData = [...data];
      updatedData[dataIndex] = {
        ...updatedData[dataIndex],
        [columnName]: value,
      };
      setData(updatedData);
      updateModifiedData(dataIndex, columnName, value);
    }
  };

  const handleUpdateClick = async () => {
    try {
      if (selectedRowIndex !== -1) {
        const selectedRow = filteredData[startIndex + selectedRowIndex];

        if (selectedRow) {
          const updatedIndex = data.findIndex(
            (item) => item.serial_number === selectedRow.serial_number
          );

          if (updatedIndex !== -1) {
            const modifiedColumns = modifiedData[updatedIndex];

            if (modifiedColumns) {
              const updatedData = [...data];
              updatedData[updatedIndex] = {
                ...updatedData[updatedIndex],
                ...modifiedColumns,
                install_day: formatDate(updatedData[updatedIndex].install_day),
              };

              const oldSerialNumber = encodeURIComponent(
                selectedRow.serial_number
              );
              const oldBusinessPartner = encodeURIComponent(
                selectedRow.business_partner
              );
              const oldCity = encodeURIComponent(selectedRow.city);
              const oldHospitalName = encodeURIComponent(
                selectedRow.hospital_name
              );

              const response = await fetch(
                `${BASE_URL}/update/${oldSerialNumber}/${hospitalCode}/${oldBusinessPartner}/${oldCity}/${oldHospitalName}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(updatedData[updatedIndex]),
                }
              );

              const receivedMessage = await response.json();
              setSelectedRowIndex(-1); // Deselect the row
              alert(receivedMessage.message);
              setModifiedData({}); // Clear modified data
              setData(updatedData); // Update the state with the modified data
            } else {
              alert("No changes to update for the selected row.");
            }
          } else {
            alert("Unable to find the selected row for update.");
          }
        }
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/hospitalCode/${hospitalCode}`);
      const data = await response.json();
      setData(data);
      setOldSerialNumbers(data.map((item) => item.serial_number));
      setOldBusinessPartner(data.map((item) => item.business_partner));
      setOldCity(data.map((item) => item.city));
      setOldHospitalName(data.map((item) => item.hospital_name));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 3,
          height: 500,
          width: "97%",
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.length === 0 ? (
          <p>No data available for showing.</p>
        ) : (
          <React.Fragment>
            <Grid
              container
              justifyContent="flex-end" // Align items to the right
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              <Grid item>
                <TextField
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  label="Search by Serial Number"
                  variant="outlined"
                  size="small"
                  sx={{ width: "220px" }}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Customer Code
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Business Partner
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Hospital Name
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Customer Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>Province</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>City</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Equipment Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Serial Number
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Install Date
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Warranty Years
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Machine Status
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Contract Type
                    </TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>Owner</TableCell>
                    <TableCell sx={{ minWidth: "80px" }}>
                      Engineer Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <TableRow
                        key={item.serial_number}
                        onClick={() => setSelectedRowIndex(index)}
                        sx={{
                          backgroundColor:
                            selectedRowIndex === index
                              ? "#f0f0f0"
                              : "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell>
                          <textarea
                            type="number"
                            value={item.customer_code}
                            onChange={(event) =>
                              handleCellChange(index, "customer_code", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.business_partner}
                            onChange={(event) =>
                              handleCellChange(index, "business_partner", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea
                            type="text"
                            value={item.hospital_name}
                            onChange={(event) =>
                              handleCellChange(index, "hospital_name", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea
                            type="text"
                            value={item.customer_type}
                            onChange={(event) =>
                              handleCellChange(index, "customer_type", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.province}
                            onChange={(event) =>
                              handleCellChange(index, "province", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.city}
                            onChange={(event) =>
                              handleCellChange(index, "city", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.equipment_type}
                            onChange={(event) =>
                              handleCellChange(index, "equipment_type", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.serial_number}
                            onChange={(event) =>
                              handleCellChange(index, "serial_number", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <input
                            type="date"
                            value={formatDate(item.install_day)}
                            onChange={(event) =>
                              handleCellChange(index, "install_day", event)
                            }
                            style={{ width: "110px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="number"
                            value={item.number_of_warranty_years}
                            onChange={(event) =>
                              handleCellChange(
                                index,
                                "number_of_warranty_years",
                                event
                              )
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.machine_status}
                            onChange={(event) =>
                              handleCellChange(index, "machine_status", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.contract_type}
                            onChange={(event) =>
                              handleCellChange(index, "contract_type", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            value={item.owner}
                            onChange={(event) =>
                              handleCellChange(index, "owner", event)
                            }
                            style={{ width: "80px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell>{item.employee_name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </TableContainer>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleUpdateClick}
              sx={{ margin: "10px auto" }}
            >
              Update Data
            </Button>
          </React.Fragment>
        )}
      </Paper>
    </Grid>
  );
};

export default UpdateTable;
