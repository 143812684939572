import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Paper, Grid, Avatar, TextField, Button } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import BASE_URL from "../helpers/baseURL";
import { useNavigate } from "react-router-dom";

//regex for email validation
const emailRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");

function ForgotPassword() {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleEmailChange = () => {
    setEmailError("");
  };

  const validate = () => {
    const email = document.getElementById("outlined-basic").value;
    let isValid = true;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email");
      isValid = false;
    }

    return isValid;
  };

  const handleSend = async () => {
    if (!validate()) return;

    const email = document.getElementById("outlined-basic").value;

    //call API to login here
    try {
      const response = await fetch(`${BASE_URL}/forgotPassword/${email}`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.message === "User not found") {
        setLoginError("User not found");
      } else {
        setLoginError("Email sent");
        //give time then navigate to login page
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 400,
          width: 280,
        }}
      >
        <Grid align="center">
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <h1>Forgot Password</h1>
          {loginError && (
            <Typography
              color="error"
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
            >
              {loginError}
            </Typography>
          )}
          <TextField
            id="outlined-basic"
            label="Email"
            placeholder="Enter Email"
            variant="outlined"
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleEmailChange}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            sx={{ margin: "10px" }}
            onClick={handleSend}
          >
            Send
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}
export default ForgotPassword;
