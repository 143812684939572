import React from "react";
import { Grid, Paper } from "@mui/material";

function WelcomePage() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
          height: 300,
          width: 370,
        }}
      >
        <h1 style={{ color: "#dc3545" }}>Welcome!</h1>
      </Paper>
    </Grid>
  );
}

export default WelcomePage;
