import React, { useState } from "react";
import { Typography, Link } from "@mui/material";
import {
  Paper,
  Grid,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BASE_URL from "../helpers/baseURL";
import { useNavigate } from "react-router-dom";

//regex for password validation that matches the following criteria:
//at least 8 characters long
//at least 1 uppercase letter
//at least 1 lowercase letter
//at least 1 number
//at least 1 special character
const passowrdRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8})"
);
//regex for email validation
const emailRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@sa.stago.com$");

function LoginCard() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = () => {
    setEmailError("");
  };

  const handlePasswordChange = () => {
    setPasswordError("");
  };

  const validate = () => {
    const email = document.getElementById("outlined-basic").value;
    const password = document.getElementById("outlined-password").value;
    let isValid = true;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email");
      isValid = false;
    }

    if (!passowrdRegex.test(password)) {
      setPasswordError("Invalid password");
      isValid = false;
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (!validate()) return;

    const email = document.getElementById("outlined-basic").value;
    const password = document.getElementById("outlined-password").value;

    //call API to login here
    try {
      const response = await fetch(`${BASE_URL}/login/${email}/${password}`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.message === "User not found") {
        setLoginError("Invalid email or password");
      } else {
        console.log(responseData.result[0].authority);
        console.log(responseData.token);
        navigate("/welcomePage");
        window.localStorage.setItem(
          "privilage",
          responseData.result[0].authority
        );
        window.localStorage.setItem("token", responseData.token);
        window.localStorage.setItem("loggedIn", true);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 400,
          width: 280,
        }}
      >
        <Grid align="center">
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <h1>Login</h1>
          {loginError && (
            <Typography
              color="error"
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
            >
              {loginError}
            </Typography>
          )}
          <TextField
            id="outlined-basic"
            label="Email"
            placeholder="Enter Email"
            variant="outlined"
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleEmailChange}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <TextField
            id="outlined-password"
            label="Password"
            placeholder="Enter Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handlePasswordChange}
            error={Boolean(passwordError)}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            sx={{ margin: "10px" }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Typography>
            <Link href="/forgot-password">Forgot password?</Link>
          </Typography>
          <Typography>
            {" "}
            Do you have an account? <Link href="/register">Sign Up</Link>
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default LoginCard;
