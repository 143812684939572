import { Box } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";

function Footer() {
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "white",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <Typography color="black" variant="h5">
                Stago
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="subtitle1">
                {` © ${new Date().getFullYear()} | Stago | DSA | Saudi Arabia`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}
export default Footer;
