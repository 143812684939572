import React, { useState } from "react";
import {
  Paper,
  Grid,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BASE_URL from "../helpers/baseURL";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//regex for password validation that matches the following criteria:
//at least 8 characters long
//at least 1 uppercase letter
//at least 1 lowercase letter
//at least 1 number
//at least 1 special character
const passowrdRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8})"
);

function ResetPassword() {
  const { email, token } = useParams();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = () => {
    setPasswordError("");
  };

  const handleConfirmPasswordChange = () => {
    setConfirmPasswordError("");
  };

  const validate = () => {
    const password = document.getElementById("outlined-password").value;
    const confirmPassword = document.getElementById(
      "outlined-confirm-password"
    ).value;
    let isValid = true;

    if (!passowrdRegex.test(password)) {
      setPasswordError("Invalid password");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    return isValid;
  };

  const handleSignup = async () => {
    if (!validate()) return;

    const password = document.getElementById("outlined-password").value;

    //call API to register
    try {
      const response = await fetch(
        `${BASE_URL}/resetPassword/${email}/${token}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: password,
          }),
        }
      );
      const responseData = await response.json();
      alert(responseData.message);
      if (responseData.message === "Password changed succesfully") {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 400,
          width: 280,
        }}
      >
        <Grid align="center">
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <h1>Reset password</h1>
          <TextField
            id="outlined-password"
            label="New Password"
            placeholder="Enter Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handlePasswordChange}
            error={Boolean(passwordError)}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-confirm-password"
            label="Confim New Password"
            placeholder="Re enter Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            sx={{ margin: "10px" }}
            onChange={handleConfirmPasswordChange}
            error={Boolean(confirmPasswordError)}
            helperText={confirmPasswordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            sx={{ margin: "10px" }}
            onClick={handleSignup}
          >
            Reset
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}
export default ResetPassword;
