const formatDate = (dateString, isEnd) => {
  if (isEnd) {
    const utcDate = new Date(dateString); // Create a Date object from the UTC string

    // Add 24 hours (in milliseconds) to the UTC date to get the next day's date
    utcDate.setTime(utcDate.getTime() + 24 * 60 * 1000);

    const formattedDate = utcDate.toISOString().split("T")[0]; // Get formatted date
    return formattedDate;
  } else {
    const utcDate = new Date(dateString); // Create a Date object from the UTC string

    // Add 24 hours (in milliseconds) to the UTC date to get the next day's date
    // utcDate.setTime(utcDate.getTime() + 24 * 60 * 60 * 1000);

    const formattedDate = utcDate.toISOString().split("T")[0]; // Get formatted date
    return formattedDate;
  }
};

export default formatDate;
