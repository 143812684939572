import { AppBar, Typography, Toolbar } from "@mui/material";
import logo from "../logo.svg";

function NavBarLogin({
  setSearchActive,
  setUpdateActive,
  setDeleteActive,
  setAddActive,
}) {
  return (
    <AppBar position="static" sx={{ bgcolor: "#F1E4E8" }}>
      <Toolbar>
        <img src={logo} alt="logo" />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
      </Toolbar>
    </AppBar>
  );
}

export default NavBarLogin;
