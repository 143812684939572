import React, { useState } from "react";
import { TextField, Grid, Paper, Button } from "@mui/material";
import BASE_URL from "../../helpers/baseURL";

function GiveAuthority() {
  const [email, setEmail] = useState("");

  const handleSearch = async () => {
    //call api to search for email and give admin privilege
    try {
      console.log("email: ", email);
      const response = await fetch(`${BASE_URL}/changeAuthority/${email}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      alert(data.message);
      //clear the input field
      setEmail("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 300,
          width: 370,
        }}
      >
        <Grid container direction="column" alignItems="center">
          <h1>Give adminstrator privilege:</h1>
          <TextField
            id="outlined-basic"
            label="Give admin privilege"
            placeholder="Email"
            variant="outlined"
            required
            sx={{
              margin: "50px auto",
              borderRadius: "10%",
              width: "300px",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={handleSearch}
            color="primary"
            variant="contained"
            sx={{ margin: "10px auto" }}
          >
            Give Authority
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}
export default GiveAuthority;
