import { AppBar, Typography, Toolbar, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../logo.svg";

function NavBarAdmin({
  setSearchActive,
  setUpdateActive,
  setDeleteActive,
  setAddActive,
  setGiveAuth,
}) {
  const handleSearchClick = () => {
    setSearchActive(true);
  };
  const handleUpdateClick = () => {
    setUpdateActive(true);
  };
  const handleDeleteClick = () => {
    setDeleteActive(true);
  };
  const handleAddClick = () => {
    setAddActive(true);
  };
  const handleGiveAuth = () => {
    setGiveAuth(true);
  };
  const handleLogout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  return (
    <AppBar position="static" sx={{ bgcolor: "#F1E4E8" }}>
      <Toolbar>
        <img src={logo} alt="logo" />
        <Typography variant="h6" component="div" sx={{ flexGrow: 0.5 }} />
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            component={Link}
            to="/give-authority"
            onClick={handleGiveAuth}
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Give Authority
          </Button>
          <Button
            component={Link}
            to="/add-data-2"
            onClick={handleAddClick}
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Add To Stock
          </Button>
          <Button
            component={Link}
            to="/add-data"
            onClick={handleAddClick}
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Add Data
          </Button>
          <Button
            component={Link}
            to="/update-data"
            onClick={handleUpdateClick}
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Update Data
          </Button>
          <Button
            onClick={handleDeleteClick}
            component={Link}
            to="/delete-data"
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Delete Data
          </Button>
          <Button
            component={Link}
            to="/search-page"
            onClick={handleAddClick}
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Reports
          </Button>
          <Button
            type="submit"
            onClick={handleLogout}
            rel="noreferrer"
            variant="text"
            sx={{
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#dc3545",
              },
            }}
          >
            Log Out
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default NavBarAdmin;
