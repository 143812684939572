import React, { useState, useEffect } from "react";
import { Grid, Paper, Button } from "@mui/material";
import BASE_URL from "../../helpers/baseURL";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
function AddDataPart1() {
  const [data, setData] = useState([]);
  const [changes, setChanges] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [engineerOptions, setEngineerOptions] = useState([]); // State for engineer options

  // Generic function to handle changes in table cells
  const handleCellChange = (columnName, event) => {
    const newData = [...data];
    newData[columnName] = event.target.value;
    setData(newData);

    // Update the changes state to track the modified columns
    setChanges((prevChanges) => ({
      ...prevChanges,
      [columnName]: event.target.value,
    }));
  };

  // Fetch engineer options from API
  const fetchEngineerOptions = async () => {
    try {
      const response = await fetch(`${BASE_URL}/equipments`); // Adjust the API endpoint
      const options = await response.json();
      setEngineerOptions(options);
    } catch (error) {
      console.error("Error fetching engineer options:", error);
    }
  };

  useEffect(() => {
    fetchEngineerOptions(); // Fetch engineer options when component mounts
  }, []);

  // Function to handle the update button click
  const handleAddClick = async () => {
    try {
      const modifiedData = { ...data }; // Create a shallow copy of the data object

      // Loop through modified columns and update the modifiedData object
      for (const columnName in changes) {
        modifiedData[columnName] = changes[columnName];
      }

      console.log("change", changes);

      // Make the API request to add to request table
      const response = await fetch(`${BASE_URL}/addRequest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(changes),
      });
      const recievedMessage = await response.json();
      //clear the input fields
      document
        .querySelectorAll("textarea")
        .forEach((input) => (input.value = ""));
      document.querySelector("select").value = "";
      //clear the employee name
      // alert(recievedMessage.message);
      if (recievedMessage.message === "Request added succesfully")
        setShowAlert(true);
      else alert(recievedMessage.message);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      // Clear the changes state after successful updates
      setChanges({});
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 300,
          width: "100%",
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showAlert ? (
          <h1 style={{ color: "#dc3545" }}>Request submitted successfully</h1>
        ) : // <h1 style={{ color: "#dc3545" }}>Error adding the request</h1>
        null}
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: "80px" }}>Serial Number</TableCell>
                  <TableCell sx={{ minWidth: "80px" }}>
                    Equipment Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <textarea
                      type="text"
                      onChange={(event) =>
                        handleCellChange("serial_number", event)
                      }
                      style={{ width: "200px", height: "40px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <select
                      onChange={(event) =>
                        handleCellChange("equipment_type", event)
                      }
                      style={{ width: "200px", height: "40px" }}
                    >
                      <option value="">Select Equipment</option>
                      {engineerOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.equipment_name}
                        </option>
                      ))}
                    </select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleAddClick}
            sx={{ margin: "10px auto" }}
          >
            Post
          </Button>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}

export default AddDataPart1;
