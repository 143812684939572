import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Grid, Paper, Button } from "@mui/material";

function UpdateData() {
  const navigate = useNavigate();
  const [hospitalCode, setHospitalCode] = useState("");

  const handleSearch = () => {
    if (!hospitalCode) {
      return;
    }
    navigate(`/update-table/${hospitalCode}`);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 300,
          width: 370,
        }}
      >
        <Grid container direction="column" alignItems="center">
          <h1>Insert Hospital Code:</h1>
          <TextField
            id="outlined-basic"
            label="Update Data"
            placeholder="Hospital Code"
            variant="outlined"
            required
            sx={{
              margin: "50px auto",
              borderRadius: "10%",
              width: "300px",
            }}
            value={hospitalCode}
            onChange={(e) => setHospitalCode(e.target.value)}
          />
          <Button
            onClick={handleSearch}
            color="primary"
            variant="contained"
            sx={{ margin: "10px auto" }}
          >
            Search
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default UpdateData;
