import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import formatDate from "../helpers/formatDate";
import BASE_URL from "../helpers/baseURL";

function DetailedTable() {
  const [data, setData] = useState([]);
  const { equipment, searchQuery } = useParams();
  console.log("equipment: ", equipment);
  console.log("search query ", searchQuery);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    console.log("here in fetch data", searchQuery);
    try {
      let apiUrl = `${BASE_URL}`;
      if (searchQuery === "true") {
        console.log("here in current year: ");
        apiUrl += `/detailsCurrentYear/${equipment}`;
      } else if (
        searchQuery &&
        (searchQuery.toLowerCase() === "western" ||
          searchQuery.toLowerCase() === "eastern" ||
          searchQuery.toLowerCase() === "central" ||
          searchQuery.toLowerCase() === "southern")
      ) {
        console.log("here in province: ");
        console.log("province: ", searchQuery);
        console.log("equipment: ", equipment);
        apiUrl += `/detailsProvince/${equipment}/${searchQuery}`;
      } else if (
        searchQuery &&
        (searchQuery.toLowerCase() === "moh" ||
          searchQuery.toLowerCase() === "mod" ||
          searchQuery.toLowerCase() === "institution" ||
          searchQuery.toLowerCase() === "private")
      ) {
        console.log("here in customer type: ");
        apiUrl += `/detailsCustomerType/${equipment}/${searchQuery}`;
      } else {
        console.log("here in equipment: ");
        apiUrl += `/detailsEquipment/${equipment}`;
      }
      const response = await fetch(apiUrl);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 10,
          height: 650,
          width: "100%",
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#fff",
              }}
            >
              <TableRow>
                <TableCell sx={{ minWidth: "70px" }}>Customer Code</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>
                  Business Partner
                </TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Hospital Name</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Customer Type</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Province</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>City</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Equipment Type</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Serial Number</TableCell>
                <TableCell sx={{ minWidth: "80px" }}>Install Date</TableCell>
                <TableCell sx={{ minWidth: "60px" }}>Warranty Years</TableCell>
                <TableCell sx={{ minWidth: "80px" }}>End of Warranty</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Machine Status</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Contract Type</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Owner</TableCell>
                <TableCell sx={{ minWidth: "70px" }}>Engineer Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ maxHeight: "400px", overflowY: "auto" }}>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.serial_number}>
                    <TableCell>{item.customer_code}</TableCell>
                    <TableCell>{item.business_partner}</TableCell>
                    <TableCell>{item.hospital_name}</TableCell>
                    <TableCell>{item.customer_type}</TableCell>
                    <TableCell>{item.province}</TableCell>
                    <TableCell>{item.city}</TableCell>
                    <TableCell>{item.equipment_type}</TableCell>
                    <TableCell>{item.serial_number}</TableCell>
                    <TableCell>{formatDate(item.install_day, false)}</TableCell>
                    <TableCell>{item.number_of_warranty_years}</TableCell>
                    <TableCell>
                      {formatDate(item.end_of_warranty, true)}
                    </TableCell>
                    <TableCell>{item.machine_status}</TableCell>
                    <TableCell>{item.contract_type}</TableCell>
                    <TableCell>{item.owner}</TableCell>
                    <TableCell>{item.employee_name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
            style={{ marginTop: "auto" }}
          />
        </TableContainer>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          sx={{ margin: "10px auto" }}
        >
          <CSVLink
            data={data}
            filename={`${equipment}.csv`}
            style={{ color: "white", textDecoration: "none" }}
          >
            Export Data
          </CSVLink>
        </Button>
      </Paper>
    </Grid>
  );
}
export default DetailedTable;
