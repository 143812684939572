import React, { useState } from "react";
import NavBarAdmin from "./components/NavBarAdmin";
import Footer from "./components/Footer";
import LoginCard from "./pages/loginCard";
import { Route, Routes } from "react-router-dom";
import SearchPage from "./pages/searchPage";
import SearchTable from "./pages/searchTable";
import UpdateData from "./pages/admin/updateData";
import UpdateTable from "./pages/admin/updateTable";
import DeleteData from "./pages/admin/deleteData";
import DeleteTable from "./pages/admin/deleteTable";
import AddDataPart1 from "./pages/admin/addDataPart1";
import AddDataPart2 from "./pages/admin/addDataPart2";
import WelcomePage from "./pages/welcomePage";
import NavBarEngineer from "./components/NavBarEngineer";
import NavBarLogin from "./components/NavBarLogin";
import Unavailable from "./pages/unavailable";
import SignUp from "./pages/signUp";
import GiveAuthority from "./pages/admin/giveAuthority";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import DetailedTable from "./pages/detailedTable";

function WebRoutes() {
  const [searchActive, setSearchActive] = useState(false);
  const [updateActive, setUpdateActive] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [addActive, setAddActive] = useState(false);
  const [giveAuth, setGiveAuth] = useState(false);

  const isLoggedIn = window.localStorage.getItem("loggedIn");
  const auth = window.localStorage.getItem("privilage");
  // const token = window.localStorage.getItem("token");
  // console.log("token: ", token);
  console.log("privilageFromStorage: ", auth);
  console.log("isLoggedIn: ", isLoggedIn);

  return (
    <React.Fragment>
      {/* Render NavBarAdmin or NavBarUser based on authority */}
      {auth === "admin" ? (
        <NavBarAdmin
          searchActive={searchActive}
          setSearchActive={setSearchActive}
          updateActive={updateActive}
          setUpdateActive={setUpdateActive}
          deleteActive={deleteActive}
          setDeleteActive={setDeleteActive}
          addActive={addActive}
          setAddActive={setAddActive}
          giveAuth={giveAuth}
          setGiveAuth={setGiveAuth}
        />
      ) : auth === "engineer" ? (
        <NavBarEngineer
          searchActive={searchActive}
          setSearchActive={setSearchActive}
          addActive={addActive}
          setAddActive={setAddActive}
        />
      ) : (
        <NavBarLogin />
      )}
      <Routes>
        {/* public paths */}
        <Route
          path="/"
          element={isLoggedIn ? <WelcomePage /> : <LoginCard />}
        />
        <Route path="/register" element={<SignUp />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route
          path="reset-password/:email/:token"
          element={<ResetPassword />}
        ></Route>

        {/* private paths for admins*/}
        {auth === "admin" && (
          <Route>
            <Route path="/update-data" element={<UpdateData />}></Route>
            <Route
              path="/update-table/:hospitalCode"
              element={<UpdateTable />}
            />
            <Route path="/delete-data" element={<DeleteData />}></Route>
            <Route
              path="/delete-table/:hospitalCode"
              element={<DeleteTable />}
            />
            {/* will have 2 different elements according to privilage */}
            <Route path="/add-data-2" element={<AddDataPart1 />}></Route>
            <Route path="/add-data" element={<AddDataPart2 />}></Route>
            <Route path="/give-authority" element={<GiveAuthority />}></Route>
          </Route>
        )}
        {/* private paths for engineers */}
        {auth === "engineer" && (
          <Route path="/add-data" element={<AddDataPart2 />}></Route>
        )}
        {/* private paths for all user types */}
        {(auth === "admin" || auth === "engineer") && (
          <Route>
            <Route path="/welcomePage" element={<WelcomePage />}></Route>
            <Route path="/search-page" element={<SearchPage />}></Route>
            <Route
              path="/search-table/:filterChoice/:searchQuery"
              element={<SearchTable />}
            />
            <Route
              path="/search-table/:filterChoice"
              element={<SearchTable />}
            />
            <Route
              path="/search-table/:filterChoice/:date1/:date2"
              element={<SearchTable />}
            />
            <Route path="/details/:equipment" element={<DetailedTable />} />
            <Route
              path="/details/:equipment/:searchQuery"
              element={<DetailedTable />}
            />
          </Route>
        )}
        <Route path="*" element={<Unavailable />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}
export default WebRoutes;
